var dict = {};
dict['0xfa9Ff941Fdc872Fd2af467bF29811983c204e06D'] =
  '0000000000000000000000000000000000000000000000000000000000000e9c';
dict['0xf6901850cd53b8C0968D41f597057AB5E88DE473'] =
  '0000000000000000000000000000000000000000000000000000000000000587';
dict['0xf38269807Cf24BF1E273091Bfb74f914DD60A76f'] =
  '0000000000000000000000000000000000000000000000000000000000000162';
dict['0x965e9724544aCD04aF9Db96c4cCe7829083f445E'] =
  '000000000000000000000000000000000000000000000000000000000000015b';
dict['0x4317C1563B39090B714c5ba8682895200986e01C'] =
  '00000000000000000000000000000000000000000000000000000000000000f0';
dict['0xEcFf9d177809060D282A3fC0f628C7F0a11c3744'] =
  '00000000000000000000000000000000000000000000000000000000000000be';
dict['0x6A7f151487fAF38ABd630B28591206f044Fc9e9a'] =
  '00000000000000000000000000000000000000000000000000000000000000be';
dict['0xa402eee84BB68B04a540cA3BF68193FA300df248'] =
  '000000000000000000000000000000000000000000000000000000000000008c';
dict['0xcD96f86dB2cD5BdA1F54aE16fdFd4193518A0c88'] =
  '0000000000000000000000000000000000000000000000000000000000000082';
dict['0x7Bc4E7D9Ad2E72F97c4FD2caA879EA321e88D5EC'] =
  '000000000000000000000000000000000000000000000000000000000000007f';
dict['0xc092B369f1eF3031AAF558DaE19103C0a66805c8'] =
  '00000000000000000000000000000000000000000000000000000000000000cb';
dict['0xBf86b233c3882dE01743ce6d856573635Bb9F9eD'] =
  '00000000000000000000000000000000000000000000000000000000000000ae';
dict['0x4b612b7913b6Bd58843823beae5F3c9F7f28d510'] =
  '000000000000000000000000000000000000000000000000000000000000007e';
dict['0xb3684f49317C684D5f1DDc50000399f48a3D9544'] =
  '000000000000000000000000000000000000000000000000000000000000006e';
dict['0xeAbC7738A83149299ff9C3B65f0cc5A4D72b088e'] =
  '0000000000000000000000000000000000000000000000000000000000000061';
dict['0xEF0F1143B4Fa66536F7579eedFee0802fd3e1DA4'] =
  '000000000000000000000000000000000000000000000000000000000000005d';
dict['0xdf4FbDE0De5CF5883095FBF1BCD78d88bC76c999'] =
  '000000000000000000000000000000000000000000000000000000000000005a';
dict['0xBee2B67cF8023084382595c9C61Cd589BDe08D55'] =
  '0000000000000000000000000000000000000000000000000000000000000046';
dict['0x4B1319d64e945b21eD3D0B9C66889BC9F2145787'] =
  '0000000000000000000000000000000000000000000000000000000000000046';
dict['0x76654D7E70264d65b6328F53b3344062447B2962'] =
  '0000000000000000000000000000000000000000000000000000000000000043';
dict['0x10552477Af823eC90345902A9B78D4C06B3a0772'] =
  '0000000000000000000000000000000000000000000000000000000000000043';
dict['0x0F77C528052A7b1910E90f14d56f8BA6CE44CB1c'] =
  '000000000000000000000000000000000000000000000000000000000000003f';
dict['0x45aeC8aC3A57A97Eb1954cCFD929008c72017C6c'] =
  '000000000000000000000000000000000000000000000000000000000000003c';
dict['0xF9F9A90Bddb429c699b36BbF94B78Be1FfFC7eAB'] =
  '0000000000000000000000000000000000000000000000000000000000000075';
dict['0x5044373861D4807A6eBcd3601A161186687451e2'] =
  '0000000000000000000000000000000000000000000000000000000000000035';
dict['0x593Ab301f24337B904DA57799241B57b2D613Fb4'] =
  '0000000000000000000000000000000000000000000000000000000000000035';
dict['0x4d1dfb2D24f65C3216047E85fd3B722E44EAA224'] =
  '0000000000000000000000000000000000000000000000000000000000000032';
dict['0x298D68804B1ae39Db335968F47B1d02c75A91156'] =
  '000000000000000000000000000000000000000000000000000000000000002f';
dict['0xEB9E7B9aA7b51001bE4917C79CcaA9502b85a00c'] =
  '000000000000000000000000000000000000000000000000000000000000002b';
dict['0x5A8c68fB257B5bf408C37a714CE79C34Aac59F2a'] =
  '0000000000000000000000000000000000000000000000000000000000000028';
dict['0x29578501919F8633825dDc16fFcb675AB29Befb7'] =
  '0000000000000000000000000000000000000000000000000000000000000025';
dict['0x3A59600181226E825131e6E4298bF45625bA1Bf3'] =
  '0000000000000000000000000000000000000000000000000000000000000025';
dict['0xe5af0f47cFc7C8324325Bbfc93C27517DeCDE23B'] =
  '0000000000000000000000000000000000000000000000000000000000000025';
dict['0x55b6bA1a5196e653080CEe0BEb0b69B050f1785A'] =
  '0000000000000000000000000000000000000000000000000000000000000021';
dict['0x8F1F9653C056b41dF1Ca4d521Fd1d16Ff8A8A00d'] =
  '000000000000000000000000000000000000000000000000000000000000003c';
dict['0x5F3812F2FD0464adaB00ca0d621077457a8C4589'] =
  '0000000000000000000000000000000000000000000000000000000000000021';
dict['0xc3e876779ca2d24E20617299cA3F25624B186Fe1'] =
  '000000000000000000000000000000000000000000000000000000000000001e';
dict['0xE245631c7c412DA8dA91faCa3E9ebB8409dc0164'] =
  '000000000000000000000000000000000000000000000000000000000000001e';
dict['0xAB9A01bF904A0a5b194b82eF9a4bE6c7088A7493'] =
  '000000000000000000000000000000000000000000000000000000000000001e';
dict['0xB9Dcc663a2319EcC8BBEeC644c6ACA15f563e2C1'] =
  '000000000000000000000000000000000000000000000000000000000000001b';
dict['0xbdC675fE7dd67Dce2370E4aBd130597b0a5B33Cf'] =
  '000000000000000000000000000000000000000000000000000000000000001b';
dict['0x2412B5303Fc318994dDEcf3A32b76A3f7747f8E9'] =
  '000000000000000000000000000000000000000000000000000000000000001b';
dict['0xe568AFb492816E8C790EC74420d7Bc008a5BD85A'] =
  '0000000000000000000000000000000000000000000000000000000000000011';
dict['0x92304Ada0194dAc83eD07f30B21ba7D0f173e979'] =
  '0000000000000000000000000000000000000000000000000000000000000017';
dict['0x5004F8310853506D6A450Fea8f43F066804d1DD6'] =
  '0000000000000000000000000000000000000000000000000000000000000017';
dict['0x1E0f0F5D57b6EaDB2739c80cE8b1865421A8cCcc'] =
  '0000000000000000000000000000000000000000000000000000000000000017';
dict['0x5a79800702eBD391d036b488BFf0f9287571296c'] =
  '0000000000000000000000000000000000000000000000000000000000000017';
dict['0x5d972ceD2108598038B8Acd0e8472d243CC39ed0'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0xf43432d53904e86883949ee7549476ab7e77ad19'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0x5802d924CE3236dF56Df3935C15053192678d8c5'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0xF7a78455E60796Ce44e6f8e60dc1947Bd38bf4B8'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0xFbDC54e06703300c39f82FE65374Fbcb620bC73d'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0x88626E2e47DB77bCCAC1fd150efbB8f16bBb3bCb'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0x4c6fBB02FD93b04Ba745cEf53712691713170A11'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0xcD935dAea8D58FCC3a5b9328a01db42670C32063'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0x22ee9e5b471b86eB559B9DeF9DAf4fe228A271c5'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0x35b1EBb8fd6DC166448ffF417fdcC69de22E5fC0'] =
  '0000000000000000000000000000000000000000000000000000000000000014';
dict['0x48b442027773e917d82C2A0035557654B94e09aA'] =
  '0000000000000000000000000000000000000000000000000000000000000011';
dict['0x5F0c42Bbd19680E22F373BF7c4b35B093e2ada81'] =
  '0000000000000000000000000000000000000000000000000000000000000011';
dict['0x85526edBBa3E0Ec1ba093d4AddE352AD63E46fef'] =
  '0000000000000000000000000000000000000000000000000000000000000011';
dict['0xb0E1a3e34907257Ea2535fC43aF834759866483C'] =
  '0000000000000000000000000000000000000000000000000000000000000011';
dict['0x136103002f0E61DbC41D793b618092d003a12c13'] =
  '0000000000000000000000000000000000000000000000000000000000000011';
dict['0x7489744813A5DA5274A47901F2e4451Ce519f058'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x06abA89a09e46560ac53A48f091F3e1a8FfACf4B'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0xb42f75b1bb059EA8FC3F4E4A045710081267bAcB'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x98526d3bf582e8D921eA45C4A5E9d2c5C108894F'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x23De8730F5050C531114bB4cE5D9a7cB8D0F7644'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0xA38b4649A73fe796464de063F1c7d05Ee3eE2320'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x25Dde356C8972aBE22fe896945bCEA50cAeec841'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x292739519f9590ef523f01138b01a70A815b9889'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x825519f883a0Fcc728F78d0c3DAaFaa2fcD1691f'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x86eFC7134498df8B569C9143c23485cE837648F0'] =
  '000000000000000000000000000000000000000000000000000000000000000d';
dict['0x585a9b66aB66E6caA7573Ad3c107a451B6E3EFC9'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x8cCf33Ea88263329F8bb8306E9b5f62AA579252A'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0xe5bB795FCd3d0Bb2f00CB3De0166Bfa321030c76'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x4699ad4EB2cBF5f083e2Fd198234707345A4d833'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x91083F4bAB816DF211a4f05d86f7Ec8529d0e65c'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x0BfFbA05bF9DBd4c4FCEaB4A92286B6FA1ebAB11'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x56999E59F692c311795223660108c92B5d802c06'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x33e7914d4A31d9Bd01f8821e0db2AC51e1Da5C4D'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x33F799a5a353110d312628f9c5CeCA6EDF22A242'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x8589fFB6f1Ba16Af16cfA4CCd3Ff60157ec8af88'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0xe0187851FcFc1F270D5437350Df963b3Ed71D98C'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x559f90276b1c9f2193891b23b3020e762efbc8e6'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x0954F540D09Ca6037016322E1730FaEbF5332560'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x2109b7Db95360F3317F32A1314d9cb82736740ff'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x500D6Ec239De39ea7e1aDc88748991B75DeF5DFe'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0xC20B3953f5A388bd3E62070a94246C7dCd58D64E'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0xaAC7e7FfE6A6253C4014B37D58F353376533AB33'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x2e645d5BD47D7c774d3875e1Efc47CC8E003fdD7'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x5ab9cdb305E57491dA476F18cE648821338AA3d0'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x12b7Cd23981F0Ea31C7DcBc00b5793b03513FDF9'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x4aEe696Fae390E5774faA1F2985719F038Bcf4Ab'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x04E386Bd9a9841721f557D93a737EF3E18C3f888'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x7749B247acf9aF887642b789fB72288A8Fb84126'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xe1c6518A215550DE500581Bf127e2Bf40134ABb3'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x55F9E8B114a6713e63fDF2bb071017E4495974C3'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x068d0A87432Aa807f5B41A34905d73C0b1377f22'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x29FF859cc59A602a2906f80f910feF57B20b3567'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xFE74A95d4aC6c3af990658170e3e8A366FF86314'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xC266f27567fc693d457e36132d52e94F45f82934'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x8A9F12fe576ac77a0a2306C4cFf71DBaF98473E3'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x2E2e164A986C3BbD9a2864027942Db5E6E1f62F2'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x37F76Aa4220f063E43ce8d6a040Bb793f9d40730'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xb7E943DE1A70aaD8D88dC9fD17D1aFC99791276b'] =
  '000000000000000000000000000000000000000000000000000000000000000a';
dict['0x7A6E26E1440344Dc42F29708Ff5b616a57358D5D'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xFd51FC5F894ebf46856fAF5aFC9FB2131f2bB034'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x0d0F4b1C9035477d140BdEd5f2466b7B030D907E'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x637970e02A0477202C4D91A4d074E1D43207a197'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xA34B456EB58ef77D79a7d6252489Cbe9aeBfae7e'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xE5Bbcf06a0Df5405800b28e7275E34ACf79bD16F'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x9960CdC24A8D723Ee71610e249207f24e48CFBFE'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x1CC5A3f8D8c271fF2EF3737a3e0bbE5602F31A3e'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x00f9933B5703CD522A12633E629395CeAA1852af'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xDC4b1eFFC6481a779BeBF1a4Ea6fE622B35945c4'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x5F28Cc5C16a301B544A85D57853a01ba655b42F8'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x23D7CCa00a59AA4CF45EBf76e34d8cDfe8Ac6905'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0x88f85e5Ec61E851c408390ef0160415eCa11D5Ce'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xec372B931263dcd62A11203527071FFF8A6cC178'] =
  '0000000000000000000000000000000000000000000000000000000000000007';
dict['0xb8426Ff2981f450452C063e4942f0e09218C622c'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x19fa05f988800c10171a7a48c7df0e7f78ae573d'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x2dD1a8DFF19007624D2a0e1300110E242E1EA1d8'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x8C0Fc2471530bEEB4039aFAC7cbf2425eF866E72'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x66FB32aCAC6607191fB653EA1d6A499b2AED7d4e'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xA142809123C2591d262339aCd05Bd4E7e8d84633'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xb622099C6cf6ffc94E204d07D12ef30279449657'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x43A16c47Ba5393a8B3B77B4301851F79D30770fC'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xaE31A87f2E9a946FC304Fa80160c052e0123b7be'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xf41164DA225Fc9530d9284413d82DbEC936265ce'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x29d761f01b655De1BDaEAe710F84fBD8d56CE437'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x563F9708deafE2C32b3406Af9ee82DE1C98110c1'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x69E32563dD7f3c7329D64D962B779c1Eb635BaE6'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xfe2f8A6fE6243ad86D5Ad17642a2C23316F978A5'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x6Ed839de81ACa1492D7Fd7364eF84598E11B4fF9'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x3aDA35c6ea8A1ADB40c0EAbc96bc325016CcE865'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x028d375e5EB25b190758Ac4aDE3d6cF699924031'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x7524C4E505108742d0957a1B691a52869bc2D3FC'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xcBA158200C9C86691461B05be6a0516C83B3E940'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xA225FC1cDF994A1AaaAC65B484d2d378Df471AFB'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x72663d37d97Ef7B817A1eeFb9186A67a5827529B'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x5F624e41AF5398ab1Ec7fdF036BB40A7B761F8b2'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xEfe08B5f62Ab5c867a3636394c95Cb7ACa218897'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xA654EA8b9BD09E2884EA80D16E4ae3aCe4A41BaE'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xa5bD947Bc9Bb9215c3290FF43f11F708b1d5B263'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xE4a0CF2773117f4aea914976dA5a1C4a2bcDb2EC'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x6691d7DC4a6D7883aF8109D8eb322494ef6B4b08'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x2f3FCbdf1bAB2928b28EC13FC1521E4f1fF247cB'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xD50C3FC0517b1103133F2aE38b786DdC7C27bfC6'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xDEe9239ca71C87b970fA76e894721956cAcA3C05'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x9043fE02AFAe3bfe765e4556f30849A19Fe99E2a'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0xf8758368190f3A6c908430B1500a9174b0e8DD08'] =
  '0000000000000000000000000000000000000000000000000000000000000003';
dict['0x8d836A3D42E1A5B770F1A3F91ad78f33650a1a72'] =
  '0000000000000000000000000000000000000000000000000000000000000003';

export const REFERRER_DICT = dict;

export const ADOPTERS = [
  '0x9F687A348233F82da7f14A88d3183E8067FeA4E0',
  '0xb238041f497df2589Cead300294f0f7F2993F254',
  '0x50Ae94A6e41f088C5C77c88c429E687Ef71124a0',
  '0xAafD5333B27351af569858Dd18F04043e2fd9890',
  '0x7Bc4E7D9Ad2E72F97c4FD2caA879EA321e88D5EC',
  '0x88626E2e47DB77bCCAC1fd150efbB8f16bBb3bCb',
  '0x23033a055b4c3ba7b8398922f2f5beae85dcd099',
  '0xeCDdDcdeA8922c92340A750af1cA24c403e883Ad',
  '0xfa9Ff941Fdc872Fd2af467bF29811983c204e06D',
  '0x2B6821d475eFf0b60C37946aE1F2bbdDa7a5612c',
  '0x53141Ae549347a25A94B0e1Ba2a5a80B35FFFF2B',
  '0x44d361e7427d870AD369d719de28DD1FF12AF54D',
  '0xa2b1F883969aef775CC62e08d2e6CaC1505Daf90',
  '0xD9F5576398Ca37C459Ab4aC0C1f46b60CfBdd98D',
  '0x500D6Ec239De39ea7e1aDc88748991B75DeF5DFe',
  '0xc961A7287957153c1E558715904731106939e2e9',
  '0xf8d054444dbC624fbAf7e0bDe92C1b0DD3417870',
  '0x989A532b9Be7848A696f4ad5Fea0570EdB5C4a53',
  '0x609444cB382bA071BF3612C1dBE6Fe5d8770d360',
  '0xcc974FF463659D200Fc0BCb66754072159b3B8C7',
  '0x7E206FfC799cDcdBD4BE0f75F7482eE94429F5cE',
  '0x018A0A88e45F23137B05E7c9260e73354b875A86',
  '0x8eC0db92A08BAcdCffff7124C79a8A5CdeD25e4e',
  '0x16f418A83925700878c911d843c0a8B29F86b149',
  '0x67b653382d5F432F7A50E182a62E24A7DD69D6D4',
  '0x3EBbB48f20f848BfB0952DAF084bBc2014c4109e',
  '0x0E73E63B5AcEbc7a8DD42FD4Dc864Ad694d6d63a',
  '0xf38269807Cf24BF1E273091Bfb74f914DD60A76f',
  '0x825519f883a0Fcc728F78d0c3DAaFaa2fcD1691f',
  '0x8bFd84879f8a9a47475277d3d0cCE15764cb6732',
  '0xc3e876779ca2d24E20617299cA3F25624B186Fe1',
  '0xbA0c299cB93Ad264c3082c2d6F9f9a98ee74b1c4',
  '0x2AF098Cd515DF2e4e2bB1a0C5C1736fE197DD7Ab',
  '0xA6Cc2C39D1de1c708Db8bF970d6E460853d1461a',
  '0x18A59B116BdFeE47Ee957C0f0e407581C82E92C0',
  '0xbd071de4D984F91330d4fAb66c323DC94D38C220',
  '0x9960CdC24A8D723Ee71610e249207f24e48CFBFE',
  '0x4562785b76B66e8F8b161cb464DC989444E99382',
  '0x9f167534e38dAC2Bc9Ca10e81Bf4C304Da0b79db',
  '0x0CEb47576da5D197492dFd101224e4f3539Df00c',
  '0xdb9BCa14031428867629D467b478a7FB556C9A0B',
  '0x4d1dfb2D24f65C3216047E85fd3B722E44EAA224',
  '0x5B44FB963085B9e24B4af41bfa210DF9c7c40Fcf',
  '0x2622CF96914Cfe9dBE169EEE7B024172075dfD97',
  '0x57Fa331b6354d88B3c3D44fe4d6EFbAb276eF971',
  '0xA4AF8aB8D4b63481A9994e6AC694A4aBF0bD42Ba',
  '0x04657F2f2Ba352bc94Dd940eDC7A7cAF3afaEd53',
  '0x67593f05C8A78a0ED97F609642F9E6809Ec586F1',
  '0x47DACccEEE2C6Cae6ae3f4D811a3AB4D6345ebBa',
  '0x609c625DdC4dE6d72F6303D42D865819677C9162',
  '0x6691d7DC4a6D7883aF8109D8eb322494ef6B4b08',
  '0x5286BFa84Ef16b6B50FCc210d11BCb7155047EF5',
  '0x5F3812F2FD0464adaB00ca0d621077457a8C4589',
  '0xef07F5d4D456098eD097941906b637f6AdDe455c',
  '0x5f344edABf75367DcbDECfFB3f9B4a0fcE928573',
  '0xFD1f45AaB6E2Cf809c793611fBfCc4E5A979B31D',
  '0xE66DB6D9790CFddE9658252D3773d896446A4D8d',
  '0x75a006106b1bA0A3980A91e3fe48fc4C11C098aE',
  '0x1cCed4289369e9072E08FE836F4e1cca6EE887Fc',
  '0xe220DdFC716066c6aBB5f1F3901a99811AcE63a9',
  '0xe598D9493B5B25F79f104d815C0FF2aB7D8Faeb5',
  '0xEeE7DD7Ef8Ab88E2690669C5D277990783761BdF',
  '0x5D346b3Bb808Ffc9307bFA00D4eD0699430EAF76',
  '0x45d7138AE43a857E7b6F1f181998C7BdDD3c0583',
  '0x5d8E89e7BDA6918e8BA4fe9EE2Bdf115F87a637B',
  '0xcD42535ACa28f18d7bd340D69558c89a3b71adc9',
  '0xf515014938e596749Aae1BD27cB3Bd73F5bA3216',
  '0x91083F4bAB816DF211a4f05d86f7Ec8529d0e65c',
  '0xF00b69cd0130A56d735F6C3D8C7C0Ed36FEC996c',
  '0x7A0D6F2bE15DD2f5Bab626E7F95958ECb3B9F1b9',
  '0xC4C5c55Ec68FF7BcD10b08bff079a955628537BC',
  '0x2f3FCbdf1bAB2928b28EC13FC1521E4f1fF247cB',
  '0x90E1216e5AF6d37a6bD43D481547468c8E03c805',
  '0x834f3C61a8B5F88289A6142BC10a6741442Fec64',
  '0xF3A7A727C46AFc411023658ab2b0a15c3Feb5599',
  '0x811C57E751df023dEAC3350FBA6833cb20029a2e',
  '0x3bEF4739453d4a5193A74fb4478ab8f47d027B97',
  '0x10cB8B9f30701B85da541038E62958D38fBa4380',
  '0xff629eaD0CE217e4E99E1714e09BE41dD387c1D8',
  '0xA81005969d1CeeaCa7A2fC4D09803Ba04bCbA601',
  '0xED8e6C8d0e2AE4BA8247d361cCD3DA67C1d4B315',
  '0xc513aF693d5eE012FA41A81f3B304025643568F0',
  '0xFa343fA7512151efEbEff0Acf3e78128ADFA981B',
  '0x5EF1e2bA8611bc83915f35953a6BC06E7f892796',
  '0xa32e9EccF6ADD4Ab4531dEFb8A30Ee878b517411',
  '0xe8DF471d4213d75d257770F3F21ccE5b1D4AC835',
  '0xEA8Ce11EEB8BfF5a8bD4947aF30367FA40cC6640',
  '0x203810b7e0A3bd34E6901435D05BfdE5C74F0Cd3',
  '0xeF934FeC13dD127A84b96524219Beee1A417b266',
  '0x7Ca3EfA7963ed5E8FcFed5Fa4571d18978ea00eA',
  '0xc092B369f1eF3031AAF558DaE19103C0a66805c8',
  '0xEf276A0BeF71131Cf67115566FE41f9a8a272b8c',
  '0x79E932fAC8B3DF6f8F226eF171c01ca9d71296e9',
  '0x8407a7C7daDd2BCfEbEf218152C80e0d27bE3e57',
  '0x6A7f151487fAF38ABd630B28591206f044Fc9e9a',
  '0x5a79800702eBD391d036b488BFf0f9287571296c',
  '0x14c516E91995D1Cc99eDF6E822152F2Ed052372a',
  '0xEcFf9d177809060D282A3fC0f628C7F0a11c3744',
  '0x0954F540D09Ca6037016322E1730FaEbF5332560',
  '0xF9F9A90Bddb429c699b36BbF94B78Be1FfFC7eAB',
  '0xeDd0a79c61f8330aCCC277143dBDF26CC4b086E6',
  '0x19dF82d415cbBEEC05c69B0B87eBe60960b2d2Ce',
  '0x654b0D401e4277d6C1d63E59714706c72747825e',
  '0x29FF859cc59A602a2906f80f910feF57B20b3567',
  '0x9EdA424C22a09b557F23Ec8fAD1F9aff55eB452D',
  '0x98fCDf3D072303fE5B1F525BCfa3Ab1CF78B39A7',
  '0x794AF9F59B91593F4eF102DA4421234FEFB852bC',
  '0x0551627a8e4DBb1432dBd54C22D3Cc592D784010',
  '0x0a1EeBBE06f6070aF47CF112e92c4938f63ec92B',
  '0x5D36F7004d73308E54d202ad35B4978781712Cc4',
  '0x1CC5A3f8D8c271fF2EF3737a3e0bbE5602F31A3e',
  '0x96651D81F0b394f82bfAa50A5C073411213eE674',
  '0x5A15227deAC33E489614Da76F13FC964e8bf3EE3',
  '0x05C4FA3528c0B838Da0D0d5BC049a5F802B6ECdE',
  '0x2b378156FbEfd6dB699d41a2F1e0F39C26672C5d',
  '0xdA5501Fed4b6E8Dd4eb2DD4A5747E1A23434D80E',
  '0x4139732e2BbbF2440fdFc202996aF9ed58a5d946',
  '0xcD935dAea8D58FCC3a5b9328a01db42670C32063',
  '0xbbFb06264ef4AEa74067a04141DF1493aE2318ea',
  '0x05019E8F79CcBF62557cE378877F564e8BB23E66',
  '0x1713C1E0f944EBfa2E27BD19E949a9Ae71C82aF2',
  '0x2315709541c2ab1237Db5988cdd8dEdD77129ccE',
  '0x128e50F8A4f1D9a53a7A0201243Fcb72E6161bcE',
  '0xc3f59c060edD97bA223130D55733cF5a353c7190',
  '0x6AC308a9B4E5882840151Cc1635fC96416eD4bF5',
  '0x1f215e0C075D95f05951A1B9856A1744979eE3c8',
  '0x52ff76968e772e371F06515B3e26dfa2Da6941b2',
  '0xC1CB4e0100ADE42542d6fAe41c9d70d208FA403E',
  '0xb390b210b9dbB08c73AEeF4c1E88A0b5400B83b3',
  '0x36A57bE6e73E232DC7E76E99E28de93ED494dbDd',
  '0xE5Bbcf06a0Df5405800b28e7275E34ACf79bD16F',
  '0xeB43d6D04D77867C57f9237bd032b42a1Dea5D8c',
  '0x6A8F198a2F23c8C7881E10fB8884499BA7554dF2',
  '0x238B7304105d66Eb254E52f81eE34182450d23f6',
  '0x931ECD63E5247DD45E44D0A65F1Df1a19Fb71F3f',
  '0x3db38058ecb910B20Cc8696e0C50185A8bD1448C',
  '0xf2d8881d41C6EE93B25CD8e699857971f7260330',
  '0xf8758368190f3A6c908430B1500a9174b0e8DD08',
  '0xAB9A01bF904A0a5b194b82eF9a4bE6c7088A7493',
  '0x003cfB61665ef816D14c6eD985f435d2A60D4eB3',
  '0x746B408FD7c50e161098Cb8d0acF7b54d76C96c1',
  '0x1bF462174AF61649Aa90E52aCcD61113DfD4F802',
  '0x0e9D5060683bec589B155D03B2AB1B9625aA726B',
  '0x43328c60eD885a64C3F5d873C20b3B6015d1A4d2',
  '0xe5af0f47cFc7C8324325Bbfc93C27517DeCDE23B',
  '0xE8575E8D45bd7942392bD151f1C22971a34eEe37',
  '0xB3ad900aeD27e0981b89CC2271A0ab0825F41165',
  '0xA9cdDeE2403aEd298B9441EA7f32f0BFdC279AD8',
  '0x8280BEfeBfeB72b2558f5F64ff3Ee326d6DD0796',
  '0x1cd61Afc132a612a45b7e85668687d43C47d905A',
  '0x7FA591899d2452943D908E1067f26ff4402DbF7b',
  '0x2da5B952DCe9Fb56269028df8EBD77DcC11C10AB',
  '0xFEA503448bca489beCEA5bbA2d11D823c814f0D6',
  '0x1634918288640b25d06126F227B2F1372b02D99e',
  '0x56A61288dE61501366688FB9a531A68Fff30458E',
  '0x81376FCcC1F8d65485341017CA2260875b22C9cc',
  '0xbFCe28F5eF713CE79B3073ced1e36fC9cD83dFB2',
  '0xaa660587924C7087920bc1dC1f40CB04d10746a5',
  '0xE1752cE7cDe3F26F89Ba9AF956AA5fF996D0Bd96',
  '0x897E1Ac1806814B49de9372A4DfEcE830f2aDB81',
  '0x6b37e581fc7C6FADA080B96B2153d1b5CC3224B8',
  '0x0E8727bD9cd48Ec0F258197b70eF1431fe149DD7',
  '0xc40571b08EDdBAED29C0F5761D20eb61EACCBD4E',
  '0xC20B3953f5A388bd3E62070a94246C7dCd58D64E',
  '0xb4Fef3D8bB2757a9b7BA6153410675A107712193',
  '0x35b1EBb8fd6DC166448ffF417fdcC69de22E5fC0',
  '0xde33EE6e04de42aA217a7e7D11cE446815b19a8f',
  '0x9043fE02AFAe3bfe765e4556f30849A19Fe99E2a',
  '0xE4a0CF2773117f4aea914976dA5a1C4a2bcDb2EC',
  '0xb3684f49317C684D5f1DDc50000399f48a3D9544',
  '0x8d836A3D42E1A5B770F1A3F91ad78f33650a1a72'
];
